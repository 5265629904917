.Toastify__toast {
    padding: 1em !important;
}

.filter-hidden {
    overflow: hidden;
    height: 75px;
}

.btn-whatsapp {
    background-color: #25D366 !important;
    border-color: #25D366 !important;
}

@media only screen and (max-width: 575px) {
    .container-fluid {
        padding-left: 8px;
        padding-right: 8px;
    }
}

.modal-update-address {
    width: 420px;
}