.item-table-dashboard {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
        font-size: 0.8em;
    }

    strong {
        font-size: 0.9em;
    }
}
