.color-variant {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.products-admin .img-wrapper {
    height: 300px;
}

.products-admin .img-wrapper .front,
.products-admin .img-wrapper .bg-size {
    height: 100%;
}

.products-admin .img-wrapper .bg-size {
    display: flex;
    justify-content: center;
    align-items: center;
}

.products-admin .img-wrapper .bg-size img {
    height: 100%;
    object-fit: contain;
}

.wrapper-thumbnail-variant {
    width: 40px;
    height: 55px;
    margin-right: 5px;
}

.wrapper-thumbnail-variant img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
}

.tag-span {
    padding: 5px 10px;
    border: 1px solid #ccc;
    color: gray;
    border-radius: 5;
    background: whitesmoke;
    margin: 0 5px;
}

.product-detail-retail-price {
    font-size: 13px;
    color: #999;

    strong {
        font-size: 12px;
    }
}
