ul.qty li {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.container-order-detail {
    p {
        margin: 0;
    }
}

.box-title {
    border-bottom: 1px solid #ddd;
    display: inline-block;
    margin-bottom: 5px;
    padding: 12px 0;
    position: relative;
    width: 100%;
    h3 {
        font-size: 16px;
        margin: 0;
        text-transform: capitalize;
        color: #333;
    }
}

.checkout-box-section {
    padding: 2em 1em;
    box-shadow: 1px 2px 5px -1px rgba(133, 133, 133, 1);
}

.description-item-checkout {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 60%;

    p {
        margin: 0;
    }
}

.form-card {
    transition: all 0.5 ease;
    height: auto;

    &-hidden {
        height: 0;
        overflow: hidden;
    }
}

.item-delivery-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2em 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 1em 0;
    &.active {
        background-color: #999;
        cursor: pointer;
    }
    &.item-dinamyc:hover {
        background-color: #999;
        cursor: pointer;
    }
}

.item-card-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 1em 0;
    &.active {
        background-color: #ccc;
        cursor: pointer;
    }

    &.item-dinamyc:hover {
        background-color: #999;
        cursor: pointer;
    }

    .info-card-options {
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            margin: 0.5em;
        }
        img {
            height: 20px;
            margin-right: 10px;
        }
    }
}

.order-label-shipment {
    width: 300px;
    height: auto;

    img {
        width: 100%;
    }

    h5 {
        text-align: center;
        margin-top: 5px;
    }
}

.review-order-title {
    padding: 1em 0.5em;
    background-color: $theme-deafult;
    margin: 0;
    h4 {
        text-transform: uppercase;
        color: white;
        margin: 0;
    }
}

.total-item-checkout {
    width: 40%;
    text-align: right;
}

.title-box,
.sub-total li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;

    p {
        margin: 0;
    }
}

.wrapper-order-item {
    width: 100%;
    padding: 0 !important;
    text-align: left;
    overflow: hidden;

    .wrapper-top-details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0;

        .status-order {
            font-size: 14px;
            margin: 0;
        }

        .time-order {
            font-weight: bold;
            text-transform: none;
        }
    }

    .quantity-order {
        font-weight: bold;
        margin-bottom: 5px;
    }

    .client-name-order {
        font-size: inherit;
    }

    .wrapper-quantity-order {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 !important;

        span {
            font-size: 13px;
        }
    }

    .wrapper-bottom-description {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 !important;

        p {
            margin: 0;
            font-weight: bold;
            font-size: 12px;
        }
    }
}

@media only screen and (max-width: 575px) {
    .rents-wrapper .card-body {
        padding: 0;
    }

    .container-order-detail .card-body {
        padding: 15px 8px;
    }

    .wrapper-order-item {
        h4 {
            font-size: 15px;
            margin-bottom: 2px;
        }

        p {
            font-size: 14px;
        }

        .wrapper-top-details {
            .status-order {
                font-size: 13px;
            }
        }
    }
}

// Modal observation
.customModal {
    width: 100%;
}
