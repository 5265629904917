.table-boxes {
    .rt-tr .rt-td:first-child {
        text-transform: capitalize;
    }
}

.badge {
    font-size: 10px;
}

.rents-wrapper .ReactTable .rt-tbody .rt-tr:hover,
.stock-wrapper .ReactTable .rt-tbody .rt-tr:hover {
    cursor: pointer;
    background-color: #eee;
}

.rt-resizable-header-content {
    padding: 8px;
}

.order-list .ReactTable .rt-th,
.order-list .ReactTable .rt-td {
    padding: 4px 4px;
}
