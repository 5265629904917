@import '../theme/variables';

body,
p {
    font-size: 16px;
}

.form-control:focus {
    box-shadow: 0 0 0 0.2rem rgba(100%, 30%, 33%, 0.25) !important;
}

.message-error-input {
    color: $color-red;
    margin: 0.3em 0;
}

.wrapper-errors {
    margin-top: 1em;
}
